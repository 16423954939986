import React from "react";
import { Link } from "react-router-dom";
import LayoutContainer from "../containers/Layout";
import CustomLink from "../components/Links";
import { useQueryClient } from "@tanstack/react-query";
import { newQuestionForm } from "./Questions/QuestionsUtils";
import { logEvent } from "firebase/analytics";
import { analytics } from "../config/FirebaseConfiguration";
import { motion } from "framer-motion";
import DiaryTarot from "../images/DiaryTarot.png";
import todayTomorrow from "../images/TodayTomorrow.png";
import pastPresentFuture from "../images/PastPresentFurture.png";
import loveCard from "../images/Love.png";
import Financial from "../images/Financial.png";
import questionmark from "../images/questionmark.png";
import questionMarks from "../images/QuestionsMarks.png";
import person from "../images/human.png";
import sevenLayout from "../images/SevenCards.png";
import yesno from "../images/YesNo.png";
import year from "../images/year.png";
import styles from "../pages/styles.css";
import MetaTags from "../components/MetaTags";
import { useAuthContext } from "../provider/Login/AuthProvider";

export default function Home() {
  const queryClient = useQueryClient();
  const userData = useAuthContext();

  const handleSubmit = (question) => {
    let newQuestion = newQuestionForm(userData, question);
    queryClient.setQueryData(["userQuestion"], newQuestion);
    logEvent(analytics, "daily_inspiration_question", "link");
  };

  return (
    <LayoutContainer>
      <MetaTags
        description={
          "Tarot reading online by AI and a personal Tarot journal diary in one place. Discover insights, track card patterns, and embark on a journey of wisdom with our unique Tarot experience."
        }
      />

      <div className="autobiography welcome-guide" css={styles}>
        <h1>Welcome to Diary Tarot!</h1>
        <Link className="align-center" to="/choose-subject-to-ask-tarot">
          <img className="card-img" src={DiaryTarot} alt="magic starts here" />
        </Link>
        <section>
          <ul>
            <li>Discover the power of Tarot AI free and only by donation.</li>
            <li>
              The website is named "Diary Tarot" because, like a personal
              journal, it offers you the chance to save and track your Tarot
              readings over time. It allowing you to revisit past cards and
              insights.
            </li>
            <li>
              What makes "Diary Tarot" truly special is the ability to spot
              patterns in your readings. Over the course of a month or even a
              year, you’ll see recurring cards, suits, and themes. This can
              provide deep insight into your journey, guiding you through the
              cycles and changes in your life.
            </li>
          </ul>
        </section>
      </div>

      <div className="frame-option">
        <Link className="align-center" to="/my-questions">
          <h2>Ask any Question</h2>
          <img
            className="card-img"
            src={questionmark}
            alt="Personal Question"
          />
        </Link>
        <ul className="guide-links">
          <CustomLink to="/my-questions">Ask any question</CustomLink>
        </ul>
      </div>

      <div className="frame-option">
        <Link className="align-center" to="/choose-subject-to-ask-tarot">
          <img
            className="card-img"
            src={todayTomorrow}
            alt="magic starts here"
          />
          <h2>Do you want to know about today or tomorrow?</h2>{" "}
        </Link>
        <ul className="guide-links">
          <CustomLink
            to="/free-question-tarot"
            onClick={() => handleSubmit("How is my day going to be today?")}
          >
            How is my day going to be today?
          </CustomLink>
          <CustomLink
            to="/free-question-tarot"
            onClick={() => handleSubmit("What should I know about tomorrow?")}
          >
            What should I know about tomorrow?
          </CustomLink>
        </ul>
      </div>
      <div className="frame-option">
        <Link className="align-center" to="/tarot-year-layout">
          <motion.h2
            animate={{ x: [50, 150, 5], opacity: 1, scale: 1 }}
            initial={{ opacity: 0, scale: 0.5 }}
            whileHover={{ scale: 1.2 }}
          >
            2025 year By Month
          </motion.h2>
          <img className="card-img" src={year} alt="year questions" />
        </Link>
        <ul className="guide-links">
          <CustomLink to="/tarot-year-layout">
            Reveal your cards for each of the month in 2025
          </CustomLink>
        </ul>
      </div>
      <div className="frame-option">
        <Link className="align-center" to="/seven-tarot-cards-layout">
          <motion.h2
            animate={{ x: [50, 150, 5], opacity: 1, scale: 1 }}
            initial={{ opacity: 0, scale: 0.5 }}
            whileHover={{ scale: 1.2 }}
          >
            Seven Cards Spread
          </motion.h2>
          <img
            className="card-img"
            src={sevenLayout}
            alt="Past, Present and Future"
          />
        </Link>
        <ul className="guide-links">
          <CustomLink to="/seven-tarot-cards-layout">
            Who I Need to Become in One Year? What will happen in one year?
          </CustomLink>
        </ul>{" "}
      </div>
      <div className="frame-option">
        <Link className="align-center" to="/yes-no-tarot">
          <motion.h2
            animate={{ x: [50, 150, 5], opacity: 1, scale: 1 }}
            initial={{ opacity: 0, scale: 0.5 }}
            whileHover={{ scale: 1.2 }}
          >
            Yes,No or Maybe
          </motion.h2>
          <img className="card-img" src={yesno} alt="yes no or maybe" />
        </Link>
        <ul className="guide-links">
          <CustomLink to="/yes-no-tarot">
            You can get an answer to Yes,No or Maybe
          </CustomLink>
        </ul>
      </div>
      <div className="frame-option">
        <a
          className="align-center"
          href="https://www.mysticsense.com/5minfree/#a_aid=diarytarotworld&a_bid=e4f7441a"
        >
          <h2>Psychics, Tarot, Astrology and more</h2>
          <img className="card-img" src={person} alt="Psychics" />
        </a>
        <ul className="guide-links">
          <li>
            <a href="https://www.mysticsense.com/5minfree/#a_aid=diarytarotworld&a_bid=e4f7441a">
              Get Free 5 Minutes Psychic Reading with a real person.
            </a>
          </li>
        </ul>
      </div>

      <div className="frame-option">
        <a
          className="align-center"
          href="https://www.mysticsense.com/Love-Psychics/#a_aid=diarytarotworld&a_bid=49c733c2"
        >
          <motion.h2
            animate={{ x: [50, 150, 5], opacity: 1, scale: 1 }}
            initial={{ opacity: 0, scale: 0.5 }}
            whileHover={{ scale: 1.2 }}
          >
            Love and Relationship in Your Life
          </motion.h2>
          <img
            className="card-img love-img"
            src={loveCard}
            alt="Love and Relationship in Your Life"
          />
        </a>
        <a href="https://www.mysticsense.com/Love-Psychics/#a_aid=diarytarotworld&a_bid=49c733c2">
          5 Minutes Free Reading from Real Person
        </a>
        <ul className="guide-links">
          <CustomLink
            to="/free-question-tarot"
            onClick={() => handleSubmit("What is he hiding from me?")}
          >
            What is he hiding from me?
          </CustomLink>
          <CustomLink
            to="/free-question-tarot"
            onClick={() => handleSubmit("What does she think about me?")}
          >
            What does she think about me?
          </CustomLink>
          <CustomLink
            to="/free-question-tarot"
            onClick={() =>
              handleSubmit("What should I do to attract him to my life?")
            }
          >
            What should I do to attract him to my life?
          </CustomLink>
          <CustomLink
            to="/free-question-tarot"
            onClick={() =>
              handleSubmit(
                "How can I enhance the quality of my current relationship?"
              )
            }
          >
            How can I enhance the quality of my current relationship?
          </CustomLink>
          <CustomLink
            to="/free-question-tarot"
            onClick={() =>
              handleSubmit(
                "What will happen if I start the relationship with this person?"
              )
            }
          >
            What will happen if I start the relationship with this person?
          </CustomLink>
          <CustomLink
            to="/free-question-tarot"
            onClick={() =>
              handleSubmit("Why is it hard for me to find a soulmate?")
            }
          >
            Why is it hard for me to find a soulmate?
          </CustomLink>
          <CustomLink
            to="/free-question-tarot"
            onClick={() => handleSubmit("What does he expect from me?")}
          >
            What does he expect from me?
          </CustomLink>
          <CustomLink
            to="/free-question-tarot"
            onClick={() =>
              handleSubmit("What was the main reason for my last breakup?")
            }
          >
            What was the main reason for my last breakup?
          </CustomLink>
          <CustomLink
            to="/free-question-tarot"
            onClick={() => handleSubmit("What does she expect from me?")}
          >
            What does she expect from me?
          </CustomLink>
        </ul>
      </div>
      <div className="frame-option">
        <Link className="align-center" to="/choose-subject-to-ask-tarot">
          <motion.h2
            animate={{ x: [50, 150, 5], opacity: 1, scale: 1 }}
            initial={{ opacity: 0, scale: 0.5 }}
            whileHover={{ scale: 1.2 }}
          >
            Topics About Finance, Health, Educations and more
          </motion.h2>
          <img
            className="card-img"
            src={Financial}
            alt="Topics About Finance"
          />
        </Link>
        <ul className="guide-links">
          <CustomLink to="/choose-subject-to-ask-tarot">
            Choose question from different topics
          </CustomLink>
        </ul>
      </div>
      <div className="frame-option">
        <Link className="align-center" to="/learn-tarot-meaning">
          <motion.h2
            animate={{ x: [50, 150, 5], opacity: 1, scale: 1 }}
            initial={{ opacity: 0, scale: 0.5 }}
            whileHover={{ scale: 1.2 }}
          >
            Get More Inshights About Tarot
          </motion.h2>
        </Link>
        <p className="guide">
          Tarot cards are a great way to gain insight into yourself and the
          world around you. You can explore the Tarot card universe and learn
          about each card in detail.
        </p>
        <ul className="guide-links">
          <CustomLink to="/how-tarot-works">
            Understanding Tarot: How Can Cards Reveal the Future or Shed Light
            on a Situation?
          </CustomLink>
          <CustomLink to="/ai-replace-human">
            Can AI Replace Human Intuition in Tarot Readings?
          </CustomLink>
          <CustomLink to="/learn-tarot-meaning">Learn Tarot Cards</CustomLink>
          <CustomLink to="/learn-tarot-meaning/quizzes">
            Test your Knowledge for Tarot Cards
          </CustomLink>
          <CustomLink to="/tarot-meaning">Search for Tarot Cards</CustomLink>
        </ul>
      </div>
      <div className="frame-option">
        <Link className="align-center" to="/choose-subject-to-ask-tarot">
          <h2>Different Kind of Questions</h2>
          <img
            className="card-img"
            src={questionMarks}
            alt="Different Kind of Questions"
          />
        </Link>
        <ul className="guide-links">
          <CustomLink
            to="/free-question-tarot"
            onClick={() =>
              handleSubmit(
                "What should I do in order to attract a soulmate to my life?"
              )
            }
          >
            What should I do in order to attract a soulmate to my life?
          </CustomLink>
          <CustomLink
            to="/free-question-tarot"
            onClick={() => handleSubmit("What will happen if I say Yes?")}
          >
            What will happen if I say Yes?
          </CustomLink>
          <CustomLink
            to="/free-question-tarot"
            onClick={() => handleSubmit("What does he think about me?")}
          >
            What does he think about me?
          </CustomLink>
          <CustomLink
            to="/free-question-tarot"
            onClick={() =>
              handleSubmit("How can I improve my financial situation?")
            }
          >
            How can I improve my financial situation?
          </CustomLink>
          <CustomLink
            to="/free-question-tarot"
            onClick={() =>
              handleSubmit(
                "What can I expect in my career over the next six months?"
              )
            }
          >
            What can I expect in my career over the next six months?
          </CustomLink>
        </ul>
      </div>
      <div className="frame-option">
        <Link className="align-center" to="/past-present-future-tarot">
          <motion.h2
            animate={{ x: [50, 150, 5], opacity: 1, scale: 1 }}
            initial={{ opacity: 0, scale: 0.5 }}
            whileHover={{ scale: 1.2 }}
          >
            Past, Present and Future
          </motion.h2>
          <img
            className="card-img"
            src={pastPresentFuture}
            alt="Past, Present and Future"
          />
        </Link>
        <ul className="guide-links">
          <CustomLink to="/past-present-future-tarot">
            Ask about Past, Present and Future
          </CustomLink>
        </ul>
      </div>
      <div className="frame-option">
        <h2>Would you like to know what will happen if you do...?</h2>
        <ul className="guide-links">
          <CustomLink
            to="/free-question-tarot"
            onClick={() =>
              handleSubmit("What will happen if I go to this trip?")
            }
          >
            What will happen if I go to this trip?
          </CustomLink>
          <CustomLink
            to="/free-question-tarot"
            onClick={() =>
              handleSubmit("What will happen if I start on this project?")
            }
          >
            What will happen if I start on this project?
          </CustomLink>
          <CustomLink
            to="/free-question-tarot"
            onClick={() =>
              handleSubmit("What will happen if I tell the truth?")
            }
          >
            What will happen if I tell the truth?
          </CustomLink>
          <CustomLink
            to="/free-question-tarot"
            onClick={() =>
              handleSubmit("What will happen if I cancel the meeting?")
            }
          >
            What will happen if I cancel the meeting?
          </CustomLink>
          <CustomLink
            to="/free-question-tarot"
            onClick={() =>
              handleSubmit("What will happen if I agree to sign the contract?")
            }
          >
            What will happen if I agree to sign the contract?
          </CustomLink>
          <CustomLink
            to="/free-question-tarot"
            onClick={() =>
              handleSubmit("What will happen if I follow my dream?")
            }
          >
            What will happen if I follow my dream?
          </CustomLink>
          <CustomLink
            to="/free-question-tarot"
            onClick={() =>
              handleSubmit("What will happen if I message to the person?")
            }
          >
            What will happen if I message to the person?
          </CustomLink>
          <CustomLink
            to="/free-question-tarot"
            onClick={() =>
              handleSubmit("What will happen if I make a call to the person?")
            }
          >
            What will happen if I make a call to the person?
          </CustomLink>
          <CustomLink
            to="/free-question-tarot"
            onClick={() => handleSubmit("What will happen if I say NO?")}
          >
            What will happen if I say NO?
          </CustomLink>
        </ul>
      </div>
      <div className="frame-option">
        <motion.h2
          animate={{ x: [50, 150, 5], opacity: 1, scale: 1 }}
          initial={{ opacity: 0, scale: 0.5 }}
          whileHover={{ scale: 1.2 }}
        >
          Referral to Other Websites
        </motion.h2>
        <ul>
          <li>
            {" "}
            <a
              href="https://karmamademedoit.com/"
              target="_blank"
              rel="noreferrer"
            >
              Karma - Self Development
            </a>
          </li>
          <li>
            {" "}
            <a
              href="https://www.mysticsense.com/Numerology/#a_aid=diarytarotworld&a_bid=c231befd"
              target="_blank"
              rel="noreferrer"
            >
              Numerology
            </a>
          </li>
          <li>
            {" "}
            <a
              href="https://www.mysticsense.com/horoscope/#a_aid=diarytarotworld&a_bid=5c4e8849"
              target="_blank"
              rel="noreferrer"
            >
              Horoscope
            </a>
          </li>
          <li>
            <a
              href="https://www.mysticsense.com/psychics/?page=1&psychic_status=1&sort=psychic_status_a#a_aid=diarytarotworld&a_bid=a44767d7"
              target="_blank"
              rel="noreferrer"
            >
              Online Psychics
            </a>
          </li>
          <li>
            {" "}
            <a
              href="https://www.mysticsense.com/Astrology-Readings/#a_aid=diarytarotworld&a_bid=e5ff77c8"
              target="_blank"
              rel="noreferrer"
            >
              5Min Free - Astrology Readings
            </a>
          </li>
          <li>
            <a
              href="https://www.mysticsense.com/Mediums/#a_aid=diarytarotworld&a_bid=e7aa6456"
              target="_blank"
              rel="noreferrer"
            >
              5Min Free - Mediums
            </a>
          </li>
        </ul>
      </div>
      <div className="frame-option">
        <Link to="/support-me">
          <h2>Support for Diary Tarot </h2>
        </Link>
        <ul className="guide-links">
          <CustomLink to="/support-me">Buy me a Coffee ☕ </CustomLink>
        </ul>
      </div>
      <div className="frame-option">
        <Link to="/about-me">
          <h2>About Me and Diary Tarot </h2>
        </Link>
        <ul className="guide-links">
          <CustomLink to="/about-me">Get to know me</CustomLink>
        </ul>
      </div>
    </LayoutContainer>
  );
}
