import React, { useState } from "react";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../config/FirebaseConfiguration";
import LayoutContainer from "../../../containers/Layout";
import CardsData from "../../../provider/CardsData";
import CardButton from "../../../components/CardButton";
import PersonalCardInfo from "../../../containers/CardInfoContainer/PersonalCardInfo";
import { pickUpCardNumber, ReversedOrNot } from "../../../utils/generateNumber";
import { useQueryClient } from "@tanstack/react-query";
import { newQuestionForm } from "../../Questions/QuestionsUtils";
import { useAuthContext } from "../../../provider/Login/AuthProvider";
import Button from "../../../components/Button";
import { useNavigate } from "react-router-dom";

export default function YearLayout({ children }) {
  const [cardsNumbers, setCardsNumbers] = useState(() => []);
  const [userQuestion, setUserQuestion] = useState(() => "");
  const [cardsHolder, setCardsHolder] = useState(() => ({
    card1: {
      meaning: "What to expect in January 2025?.",
      card: {},
      isRevealed: false,
      isReversed: false,
    },
    card2: {
      meaning: "What to expect in February 2025?.",
      card: {},
      isRevealed: false,
      isReversed: false,
    },
    card3: {
      meaning: "What to expect in March 2025?.",
      card: {},
      isRevealed: false,
      isReversed: false,
    },
    card4: {
      meaning: "What to expect in April 2025?.",
      card: {},
      isRevealed: false,
      isReversed: false,
    },
    card5: {
      meaning: "What to expect in May 2025?.",
      card: {},
      isRevealed: false,
      isReversed: false,
    },
    card6: {
      meaning: "What to expect in June 2025?.",
      card: {},
      isRevealed: false,
      isReversed: false,
    },
  }));

  const { data: cards } = CardsData();
  const queryClient = useQueryClient();
  const userData = useAuthContext();
  //const userData = queryClient.getQueryData(["userData"]);

  function cardClicked(meaning, numberOfCard) {
    let newQuestion = newQuestionForm(userData, meaning);
    setUserQuestion(meaning);
    //saveQuestion.mutate(newQuestion);
    queryClient.setQueryData(["userQuestion"], newQuestion);
    let randomCard = pickUpCardNumber(cardsNumbers);
    setCardsNumbers((prev) => [...prev, randomCard]);
    let cardChosen = cards[randomCard];

    setCardsHolder((prevCards) => ({
      ...prevCards,
      [numberOfCard]: {
        meaning: meaning,
        card: cardChosen,
        isRevealed: true,
        isReversed: ReversedOrNot(),
      },
    }));
    logEvent(analytics, "click_year_cards", "button");
  }

  const navigate = useNavigate();

  async function saveCard(chosenCard, isReversed) {
    let userQuestion = queryClient.getQueryData(["userQuestion"]);
    userQuestion.cards.push(chosenCard.name);
    userQuestion.cardsImg.push(chosenCard.img);
    userQuestion.suits.push(chosenCard.suit);
    userQuestion.isReversed.push(isReversed);
    userQuestion.numberOfCards = userQuestion.numberOfCards + 1;
    queryClient.setQueryData(["userQuestion"], userQuestion);
    logEvent(analytics, "click_save_button", "button");
    if (!userData) {
      navigate("/login");
    } else {
      navigate("/saved-card");
    }
  }
  return (
    <LayoutContainer>
      <h1>2025 Year by Month</h1>
      <div className="page-container">
        <div className="guide">
          This Year Tarot spread offers a quick and straightforward way to gain
          insights into your goals for the coming year. It reveals what you need
          to know and aim for in the year ahead.
        </div>

        <h2>January 2025</h2>
        {!cardsHolder.card1.isRevealed && (
          <div className="guide-to-click">
            <div className="guide-to-click-the-card">Click On Card Below</div>
            <CardButton
              onClick={() => cardClicked(cardsHolder.card1.meaning, "card1")}
            />
          </div>
        )}
        {cardsHolder.card1.isRevealed && (
          <PersonalCardInfo
            chosenCard={cardsHolder.card1.card}
            personalMeaning={2}
            isReversed={cardsHolder.card1.isReversed}
            userQuestion={userQuestion}
          />
        )}
        {cardsHolder.card1.isRevealed && (
          <Button
            onClick={() =>
              saveCard(cardsHolder.card1.card, cardsHolder.card1.isReversed)
            }
          >
            Save Answer
          </Button>
        )}
        <div className="mysticsense_recomendation">
          Here, you get a
          <a href="https://www.mysticsense.com/psychics/?page=1&psychic_status=1&sort=psychic_status_a#a_aid=diarytarotworld&a_bid=a44767d7">
            {" "}
            5 Minutes Free - Online Conversation with Real Person
          </a>
        </div>
        <h2>February 2025</h2>
        {!cardsHolder.card2.isRevealed && (
          <div className="guide-to-click">
            <div className="guide-to-click-the-card">Click On Card Below</div>
            <CardButton
              onClick={() => cardClicked(cardsHolder.card2.meaning, "card2")}
            />
          </div>
        )}
        {cardsHolder.card2.isRevealed && (
          <PersonalCardInfo
            chosenCard={cardsHolder.card2.card}
            personalMeaning={2}
            isReversed={cardsHolder.card2.isReversed}
            userQuestion={userQuestion}
          />
        )}
        {cardsHolder.card2.isRevealed && (
          <Button
            onClick={() =>
              saveCard(cardsHolder.card2.card, cardsHolder.card2.isReversed)
            }
          >
            Save Answer
          </Button>
        )}
        <h2>March 2025</h2>
        {!cardsHolder.card3.isRevealed && (
          <div className="guide-to-click">
            <div className="guide-to-click-the-card">Click On Card Below</div>
            <CardButton
              onClick={() => cardClicked(cardsHolder.card3.meaning, "card3")}
            />
          </div>
        )}
        {cardsHolder.card3.isRevealed && (
          <PersonalCardInfo
            chosenCard={cardsHolder.card3.card}
            personalMeaning={2}
            isReversed={cardsHolder.card3.isReversed}
            userQuestion={userQuestion}
          />
        )}
        {cardsHolder.card3.isRevealed && (
          <Button
            onClick={() =>
              saveCard(cardsHolder.card3.card, cardsHolder.card3.isReversed)
            }
          >
            Save Answer
          </Button>
        )}
        <h2>April 2025</h2>
        {!cardsHolder.card4.isRevealed && (
          <div className="guide-to-click">
            <div className="guide-to-click-the-card">Click On Card Below</div>
            <CardButton
              onClick={() => cardClicked(cardsHolder.card4.meaning, "card4")}
            />
          </div>
        )}
        {cardsHolder.card4.isRevealed && (
          <PersonalCardInfo
            chosenCard={cardsHolder.card4.card}
            personalMeaning={2}
            isReversed={cardsHolder.card4.isReversed}
            userQuestion={userQuestion}
          />
        )}
        {cardsHolder.card4.isRevealed && (
          <Button
            onClick={() =>
              saveCard(cardsHolder.card4.card, cardsHolder.card4.isReversed)
            }
          >
            Save Answer
          </Button>
        )}
        <h2>May 2025</h2>
        {!cardsHolder.card5.isRevealed && (
          <div className="guide-to-click">
            <div className="guide-to-click-the-card">Click On Card Below</div>
            <CardButton
              onClick={() => cardClicked(cardsHolder.card5.meaning, "card5")}
            />
          </div>
        )}
        {cardsHolder.card5.isRevealed && (
          <PersonalCardInfo
            chosenCard={cardsHolder.card5.card}
            personalMeaning={2}
            isReversed={cardsHolder.card5.isReversed}
            userQuestion={userQuestion}
          />
        )}
        {cardsHolder.card5.isRevealed && (
          <Button
            onClick={() =>
              saveCard(cardsHolder.card5.card, cardsHolder.card5.isReversed)
            }
          >
            Save Answer
          </Button>
        )}
        <h2>June 2025</h2>
        {!cardsHolder.card6.isRevealed && (
          <div className="guide-to-click">
            <div className="guide-to-click-the-card">Click On Card Below</div>
            <CardButton
              onClick={() => cardClicked(cardsHolder.card6.meaning, "card6")}
            />
          </div>
        )}
        {cardsHolder.card6.isRevealed && (
          <PersonalCardInfo
            chosenCard={cardsHolder.card6.card}
            personalMeaning={2}
            isReversed={cardsHolder.card6.isReversed}
            userQuestion={userQuestion}
          />
        )}
        {cardsHolder.card6.isRevealed && (
          <Button
            onClick={() =>
              saveCard(cardsHolder.card6.card, cardsHolder.card6.isReversed)
            }
          >
            Save Answer
          </Button>
        )}
      </div>

      <div className="mysticsense_recomendation">
        Here, you get a
        <a href="https://www.mysticsense.com/psychics/?page=1&psychic_status=1&sort=psychic_status_a#a_aid=diarytarotworld&a_bid=a44767d7">
          {" "}
          5 Minutes Free - Online Conversation with Real Person
        </a>
      </div>
    </LayoutContainer>
  );
}
